import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jricher/Projects/oauth.xyz-site/src/components/layout.js";
import { Link } from 'gatsby';
import Request from '../components/request';
import SEO from '../components/seo';
import Code from '../components/code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Request" keywords={['oauth', 'authorization', 'security']} mdxType="SEO" />
    <h1>{`Request`}</h1>
    <p>{`The client begins the delegation process by creating a grant request. This is a `}<inlineCode parentName="p">{`JSON`}</inlineCode>{` document `}<inlineCode parentName="p">{`POST`}</inlineCode>{`ed to the authorization server's grant endpoint. This same request starts the transaction regardless of the type of client, the type of resource being accessed, or the type of authorizations needed to access said resources. `}</p>
    <p>{`The request contains several parts:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`access_token`}</inlineCode>{` describes the rights and properties of any access tokens that the client instance is requesting`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`subject`}</inlineCode>{` contains a request for information about the current end user that the client instance is requesting from the AS`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`client`}</inlineCode>{` identifies the instance of client software using a key the client is able to provide proof of possession for during this transaction and any related actions`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`user`}</inlineCode>{` contains assertions about the current user that the client knows`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`interact`}</inlineCode>{` contains information about how the client is able to get the user to interact with the AS, if necessary`}</li>
    </ul>
    <p>{`Some of these values can be sent by reference instead of by value, as described below. The AS can also limit which values can be used together, such as only allowing certain callback URIs or display information for a particular key handle. `}</p>
    <p>{`Note that the JSON in many sections is potentially polymorphic. For example, when sent as a fully-formed request, `}<inlineCode parentName="p">{`client`}</inlineCode>{` is a JSON `}<inlineCode parentName="p">{`object`}</inlineCode>{` but when sent as an instance identifier it is sent as a JSON `}<inlineCode parentName="p">{`string`}</inlineCode>{`. `}</p>
    <p>{`This interactive example shows how the different sections can be composed with each other in a request.`}</p>
    <Request mdxType="Request" />
    <p>{`Each of the sections is detailed below. `}</p>
    <p>{`The client instance has to protect all requests to the AS by `}<Link to="/keys" mdxType="Link">{`signing the request with its key`}</Link>{`.`}</p>
    <p>{`The AS will send a `}<Link to="/response" mdxType="Link">{`response`}</Link>{` back to the client.`}</p>
    <h2>{`Access Token`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` parameter allows the client instance to request one or more access tokens from the AS, for use at downstream resource servers. The client can signal a number of different behaviors, including whether the resulting token is bound to a signing key (by default, it is), what kinds of access are requested for the token, and even whether multiple access tokens are requested. The client instance can also signal advanced functionality, such as whether or not a given access token could be split into multiple resulting access tokens by the AS.`}</p>
    <p>{`When requesting a single access token, the `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` object contains the following fields:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`access`}</inlineCode>{` describes the resources the access token should be bound to`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`label`}</inlineCode>{` provides a unique, client-chosen label for the resulting access token`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`flags`}</inlineCode>{` allows the client to signal advanced functionality`}</li>
    </ul>
    <Code code={{
      access_token: {
        label: "token1",
        access: [{
          type: "example.com/resource-set",
          actions: ["read", "write", "dolphin"],
          locations: ["https://server.example.net/", "https://resource.local/other"],
          datatypes: ["metadata", "images"]
        }],
        flags: ["bearer", "split"]
      }
    }} mdxType="Code" />
    <h3>{`Access`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`access`}</inlineCode>{` parameter contains a list of all resources and APIs the client wishes to access. Each object in this array lists the types of actions the client can take, the URIs where those actions can be taken, and the kinds of data that the client expects access to. `}</p>
    <Code code={{
      access: [{
        type: "example.com/resource-set",
        actions: ["read", "write", "dolphin"],
        locations: ["https://server.example.net/", "https://resource.local/other"],
        datatypes: ["metadata", "images"]
      }]
    }} mdxType="Code" />
    <p>{`An AS can publish pre-set resources as resource references that can be passed by the client to the AS during a transaction request, similar to OAuth 2's `}<inlineCode parentName="p">{`scope`}</inlineCode>{` concept. The AS can determine a default set of resources for a client not asking for anything specifically.`}</p>
    <Code code={{
      access: ["foo", "bar", "dolphin-metadata"]
    }} mdxType="Code" />
    <p>{`The client can send several of these objects or references in a single request, which the AS interprets as the client requesting access to all of the resources with a single access token.`}</p>
    <h3>{`Multiple Access Tokens`}</h3>
    <p>{`Usually, a client instance will request a single access token, in which case the client instance sends a single object as the value of the `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` object. However, GNAP allows advanced clients to request several tokens at once on a single request. To request multiple access tokens, the client sends an array instead of an object for the value of the `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` parameter. The values of this array are the same objects used in a single access token request, with one additional requirement: Each individual access tokens request object must include a unique `}<inlineCode parentName="p">{`label`}</inlineCode>{` for that token. Each item in the `}<inlineCode parentName="p">{`access_token`}</inlineCode>{` array will correspond to a different named access token in the response. `}</p>
    <Code code={{
      access_token: [{
        label: "token1",
        access: [{
          type: "example.com/resource-set",
          actions: ["read", "write", "dolphin"],
          locations: ["https://server.example.net/", "https://resource.local/other"],
          datatypes: ["metadata", "images"]
        }]
      }, {
        label: "token2",
        access: [{
          type: "example.com/resource-set",
          actions: ["foo", "bar", "dolphin"],
          locations: ["https://resource.other/"],
          datatypes: ["data", "pictures"]
        }]
      }]
    }} mdxType="Code" />
    <p>{`In this specific example, the client is requesting `}<inlineCode parentName="p">{`token1`}</inlineCode>{` to access the first resource and `}<inlineCode parentName="p">{`token2`}</inlineCode>{` to access the second resource. See more about what this means in the `}<Link to="/tokens" mdxType="Link">{`section on tokens`}</Link>{`.`}</p>
    <h3>{`Flags`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`flags`}</inlineCode>{` parameter allows the client instance to specify specific advanced behavior to be associated with the requested access token.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`bearer`}</inlineCode>{` flag indicates that the client does not want the resulting access token to be bound to a key for presentation at the RS. Without this flag, the access token must be presented with proof of the client's key using the same mechanism the client used to make the original request.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`split`}</inlineCode>{` flag indicates that the client is prepared for the AS to split the requested token into multiple access tokens in the response. Without this flag, a request for a single access token will never result in multiple access tokens. The `}<inlineCode parentName="p">{`label`}</inlineCode>{` values for the resulting split tokens are at the discretion of the AS that processes the split.`}</p>
    <h2>{`Interact`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`interact`}</inlineCode>{` parameter lets the client tell the AS how to get a user to interact with the AS during a transaction. A client could be capable of multiple forms of interaction with the user, from redirecting to an arbitrary URL, to displaying a user code, to relaying messages to another piece of software. `}</p>
    <p>{`More detail is found in the `}<Link to="/interaction" mdxType="Link">{`Interaction`}</Link>{` section.`}</p>
    <h2>{`Client`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`client`}</inlineCode>{` parameter allows the client instance to identify itself to the AS during its request. In GNAP, client software could have multiple distinct instances, depending on the nature of the software and its deployment. A mobile application, for example, would have many individual, distinct instances, while a web application would likely only have one. Furthermore, a client instance could be long-lived or ephemeral. A mobile application will need ongoing access long after the initial authorization, while an SPA instance will cease to exist at the end of the browser session. It's up to the AS to determine what kinds of clients it will accept connections from, including whether it will require client developers to pre-register client instance keys or derive keys from some known source, like a certificate authority.`}</p>
    <p>{`The client instance can identify its aspects by value, sending an object as the `}<inlineCode parentName="p">{`client`}</inlineCode>{` parameter in the initial request. This allows the client to identify itself to the AS and provide all the information necessary for the AS to start the transaction. Alternatively, the client can present an instance identifier by sending this identifier as a string in the `}<inlineCode parentName="p">{`client`}</inlineCode>{` parameter. The AS dereferences this string to determine whatever client information is needed to start the transaction. The instance identifier can be generated during a static registration process or dynamically from the response of the AS.`}</p>
    <h3>{`Key`}</h3>
    <p>{`In GNAP, each distinct client instance is identified by its key. If two copies of the same software have the same key, they are functionally the same client instance. If one copy of a piece of client software has multiple keys, each key represents a separate instance from the perspective of GNAP.`}</p>
    <p>{`The client instance can pass its key information to the AS by value or reference using the `}<inlineCode parentName="p">{`key`}</inlineCode>{` parameter, thereby identifying the client instance making the call. When passed by value, the `}<inlineCode parentName="p">{`key`}</inlineCode>{` object contains the key data in one of several formats, including X.509 certificates or JWKs. The `}<inlineCode parentName="p">{`proof`}</inlineCode>{` parameter indicates how the client instance can provide proof of those keys, and may be one of several values including `}<inlineCode parentName="p">{`httpsig`}</inlineCode>{` or `}<inlineCode parentName="p">{`mtls`}</inlineCode>{`. When passed by reference, the key value is a simple opaque string identifier that the AS dereferences to find the key material.`}</p>
    <p>{`Any keys referenced here, whether by value or by reference, have to be proved by the client during every request in the transaction, whether the initial request or a continuation request. More detail is found in the `}<Link to="/keys" mdxType="Link">{`Keys`}</Link>{` section. `}</p>
    <h3>{`Display`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`display`}</inlineCode>{` section describes the client software that is making the request. This information can be used during any interaction with the user that the AS might need to do, and for auditing and review of authorization decisions. `}</p>
    <h2>{`Subject`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`subject`}</inlineCode>{` parameter lets the client request information about the user. The presence of this field indicates to the AS that the client wants to know who is logged in at the AS during the interaction process, and to have that information returned to the client.`}</p>
    <p>{`This is distinct from the `}<inlineCode parentName="p">{`user`}</inlineCode>{` parameter below, which contains information that the client already knows about the current user. `}</p>
    <h2>{`User`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`user`}</inlineCode>{` parameter lets the client pass information to the AS about the current user in the form of an assertion or other set of claims gathered from a trusted source. Any assertions presented usually need to be validated by the AS, and it should be additionally validated that the client is an authorized holder of this information.  The client can also present a set of possible subject identifiers that the AS may know the user by, though these are hints and can't be taken as proof that the user is present.`}</p>
    <p>{`While this does not necessarily prove that the user in question is actually present with the client, it can allow the AS to make authorization decisions without user interaction when the AS can trust the client to present this information. For example, if the AS has previously seen the user interact with this client and the client software is trusted, the authorization server could decide to process any requests as if that user's presence were already accounted for. Therefore if something is requested that does not require additional authorization, the AS does not need to interact with the user.`}</p>
    <p>{`The AS can dynamically generate a user handle and return it to the client in the response. This handle can be used by the same piece of software to identify the same user in future transactions. In this way, it is similar to the persistent claims token from UMA 2.`}</p>
    <p>{`This is distinct from the `}<inlineCode parentName="p">{`subject`}</inlineCode>{` parameter above, which is about the client asking for information about the current user.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      